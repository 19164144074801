import { Service } from "../decorators/service.decorator";
import axios from 'axios';
import { Environment } from "../environments/Environment";

@Service()
export class ApiService {

  post(url, formData) {
    return axios.post(`${ Environment.apiUrl }/${ url }`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  get(url, params) {
    return axios.get(`${ Environment.apiUrl }/${ url }`, params, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
}
