export class NavComponent {

  _navEl = null;
  _menuElements = [];
  _openMenuButtonEl = null;
  _closeMenuButtonEl = null;
  _mobileMenuEl = null;

  constructor() {
    this.init();
  }

  init() {
    this._navEl = document.querySelector('.nav');

    if (this._navEl) {
      this.afterInit();
    }
  }

  afterInit() {
    this._menuElements = Array.from(this._navEl.querySelectorAll('.menu > li > a'));
    this._mobileMenuEl = this._navEl.querySelector('.nav__container__right');
    this._openMenuButtonEl = this._navEl.querySelector('.open-menu-button');
    this._closeMenuButtonEl = this._navEl.querySelector('.close-menu-button');

    this.menuItemsActions();
    this.initListeners();
  }

  initListeners() {
    window.addEventListener('popstate', this.onPopState.bind(this));

    window.addEventListener('urlHashChanged', this.onUrlHashChanged.bind(this));

    window.addEventListener('click', this.onClickAnywhere.bind(this));

    this._openMenuButtonEl.addEventListener('click', this.onOpenMenuButtonElClick.bind(this));

    this._closeMenuButtonEl.addEventListener('click', this.onCloseMenuButtonElClick.bind(this));
  }

  menuItemsActions() {
    if (!location.pathname.includes('/job-offers/')) {
      const fItemToMarkAsInactive = this._menuElements.find(x => x.classList.contains('active'));
      const fItemToMarkAsActive = this._menuElements.find(x => x.href.includes(this.hash));

      if (fItemToMarkAsInactive) {
        fItemToMarkAsInactive.classList.remove('active');
      }

      if (fItemToMarkAsActive && this.hash !== '') {
        fItemToMarkAsActive.classList.add('active');
      }
    }
  }

  onOpenMenuButtonElClick() {
    this._isMobileMenuOpened = true;
    this._mobileMenuEl.classList.add('active');
  }

  onCloseMenuButtonElClick() {
    this._isMobileMenuOpened = false;
    this._mobileMenuEl.classList.remove('active');
  }

  onClickAnywhere(e) {
    if (
      this._isMobileMenuOpened &&
      !e.target.closest('.nav__container__right') &&
      !e.target.closest('.open-menu-button')
    ) {
      this._closeMenuButtonEl.click();
    }
  }

  onUrlHashChanged() {
    this.menuItemsActions();
  }

  onPopState() {
    this.menuItemsActions();

    if (this._isMobileMenuOpened) {
      this._closeMenuButtonEl.click();
    }
  }

  get hash() {
    return new URL(window.location.href).hash;
  }
}
