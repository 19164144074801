export class CVForm {

  _form = null;
  _showHiddenInputCheckbox;
  _hiddenInput;
  _sendCVBtn;
  _loader;
  _FormData;
  _notification;

  constructor() {
    this.init();
  }

  init() {
    console.log("WORKSS");
    this._form = document.querySelector('#CVForm');
    this._showHiddenInputCheckbox = document.querySelector('#Others');
    this._hiddenInput = document.querySelector('.hidden-input');
    this._sendCVBtn = document.querySelector('.send-cv-btn');
    this._loader = document.querySelector('.loader');
    this._notification = document.querySelector('.after-send-notification')

    if (this._form) {
      this.afterInit();
    }
  }

  afterInit() {
    this._showHiddenInputCheckbox.addEventListener('click', () => this._hiddenInput.classList.toggle('hidden'));

    this._form.addEventListener('submit', e => {
      e.preventDefault();

      this.showLoaderOnSubmit(this._sendCVBtn);
      this.prepareDataFormat();
    });
  }

  prepareDataFormat() {
    const formData = new FormData(this._form);
    const value = Object.fromEntries(formData.entries());
    value.techStack = formData.getAll('tech-stack');
    const nameAndSurname = value.fullName.split(' ')

    const data = {
      phone: value.phone,
      email: value.email,
      techStack: value.techStack,
      Others: value.others
    }

    let request = {};
    if (nameAndSurname.length === 2) {
      request = {
        FirstName: nameAndSurname[0],
        LastName: nameAndSurname[1],
        Data: JSON.stringify(data)
      }
    } else {
      request = {
        FirstName: value.fullName,
        LastName: 'No Data',
        Data: JSON.stringify(data)
      }
    }

    this.sendData(
      'https://api-website.createctrl.cloud/api/v1/emails/conference-contact',
      JSON.stringify(request)
    )
  }

  sendData(endpoint, payload) {
    fetch(endpoint, {
      method: 'POST',
      body: payload,
      headers: {'Content-Type': 'application/json'}
    })
      .then(this.hideLoaderAfterSend.bind(this))
      .catch(error => {
        console.log('ERROR', error);
      })
  }

  showLoaderOnSubmit(button) {
    button.classList.add('hidden');
    this._loader.classList.remove('hidden');
    this._form.classList.add('prevent-click');
  }

  hideLoaderAfterSend() {
    this._notification.classList.remove('hidden');
    this._form.classList.add('hidden');
  }
}
