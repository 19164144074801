import { ModalComponent } from "../components/modals/modal.component";
import { JobApplyFormComponent } from "../components/forms/job-apply-form.component";

export class JobOfferLayout {

  _jobOfferPageEl = null;
  _jobApplyModal = null;
  _jobApplyFormComponent = null;

  constructor() {
    this.init();
  }

  init() {
    this._jobOfferPageEl = document.querySelector('.job-offer-page');

    if (this._jobOfferPageEl) {
      this.afterInit();
    }
  }

  afterInit() {
    const applyJobButtons = this._jobOfferPageEl.querySelectorAll('.apply-job-button');

    this._jobApplyModal = new ModalComponent('job-apply-modal');

    applyJobButtons.forEach(x => {
      x.addEventListener('click', () => {
        if (!this._jobApplyModal.isVisible) {
          this._jobApplyModal.show();
          this.initJobApplyFormComponent();
        }
      });
    });
  }

  initJobApplyFormComponent() {
    if (!this._jobApplyFormComponent) {
      this._jobApplyFormComponent = new JobApplyFormComponent();
    }
  }
}
