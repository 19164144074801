import './components/file-input.component';
import { NavComponent } from "./components/nav.component";
import { ContactSectionComponent } from "./components/contact-section.component";
import { CookiesComponent } from "./components/cookies.component";
import { JobOfferLayout } from "./layouts/job-offer.layout";
import { ScrollSpy } from "./classes/ScrollSpy";
import { SendCvSectionComponent } from "./components/send-cv-section.component";
import { CVForm } from "./classes/CVForm";

new NavComponent();
new ContactSectionComponent();
new CookiesComponent();
new JobOfferLayout();
new ScrollSpy();
new CVForm();
new SendCvSectionComponent();
