import { Service } from "../decorators/service.decorator";
import { ApiService } from "./ApiService";

@Service()
export class EmailsService {

  _basePath;
  _apiService;

  constructor() {
    this._basePath = 'emails/send-email';
    this._apiService = new ApiService();
  }

  sendEmail(formData) {
    return this._apiService.post(this._basePath, formData);
  }
}
