const template = document.createElement('template');

template.innerHTML = `
  <label for="cv-file-input" class="cv-file-label">
    <i class="fa-solid fa-file-arrow-up"></i> Attach your application document <span>(max 2MB)</span>
    <input id="cv-file-input" type="file" accept="application/pdf" name="file" required>
  </label>
  <div class="show-file-info-wrapper d-flex justify-content-between" hidden>
    <div class="d-flex">
      <img src="/assets/img/icons/attachment.svg" alt="">
        <p></p>
    </div>
    <i class="fa-solid fa-trash-can"></i>
  </div>
`;

class fileInputComponent extends HTMLElement {
  _fileInput;
  _fileInfoBox;
  _fileInfoBoxText;
  _deleteButton;

  connectedCallback() {
    this.appendChild(template.content.cloneNode(true));
    this._fileInput = document.querySelector('#cv-file-input');
    this._fileInfoBox = document.querySelector('.show-file-info-wrapper');
    this._fileInfoBoxText = document.querySelector('.show-file-info-wrapper p');
    this._deleteButton = document.querySelector('.show-file-info-wrapper .fa-trash-can');

    this._fileInput.addEventListener('change', this.onFileChange.bind(this));
    this._deleteButton.addEventListener('click', this.onFileDelete.bind(this));
  }

  onFileChange() {
    if (this._fileInput.files.length) {
      this._fileInfoBox.removeAttribute('hidden');
      this._fileInfoBoxText.innerHTML = this._fileInput.files.item(0).name;
    } else {
      this.onFileDelete();
    }
  }

  onFileDelete() {
    this._fileInfoBox.setAttribute('hidden', true);
    this._fileInput.value = null;
  }
}

window.customElements.define('file-input', fileInputComponent);
