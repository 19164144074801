import { EmailsService } from "../services/EmailsService";
import { Captcha } from "../classes/Captcha";

export class AbstractForm {

  formData;
  formEl;

  _emailType = null;
  _emailsService = null;
  _submitButtonEl = null;
  _formNotificationSuccess = null;
  _formNotificationError = null;

  constructor(formEl, emailType) {
    this.formEl = formEl;
    this._emailsService = new EmailsService();
    this._emailType = emailType;

    if (this.formEl) {
      this.init();
    }
  }

  init() {
    this._submitButtonEl = this.formEl.querySelector('button[type="submit"]');
    this._formNotificationSuccess = this.formEl.querySelector('.form-notification--success');
    this._formNotificationError = this.formEl.querySelector('.form-notification--error');

    this.formEl.addEventListener('submit', e => {
      e.preventDefault();

      this.sendEmail();
    });
  }

  afterSuccessfulSend() {}

  sendEmail() {
    Captcha.execute(this._emailType, this.formEl, () => {
      this.formData = new FormData(this.formEl);

      this.formData.append('emailType', this._emailType);
      this.formData.append('lang', 'pl');

      this._formNotificationSuccess.setAttribute('hidden', 'true');
      this._formNotificationError.setAttribute('hidden', 'true');
      this._submitButtonEl.setAttribute('data-text', 'sending...');
      this.formEl.classList.add('pointer-events-none');

      this._emailsService.sendEmail(this.formData).then(() => {
        this.afterSuccessfulSend();
        this.formEl.reset();
        this._formNotificationSuccess.removeAttribute('hidden');
      }).catch(() => {
        this._formNotificationError.removeAttribute('hidden');
      }).finally(() => {
        this.formEl.classList.remove('pointer-events-none');
        this._submitButtonEl.setAttribute('data-text', 'send');
      });
    });
  }
}
