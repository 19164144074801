export const Service = function () {

  return (target) => {

    return class extends target {

      constructor() {
        super();

        if (!this.constructor.instance) {
          this.constructor.instance = this;
        }

        return this.constructor.instance;
      }
    };
  };
};
