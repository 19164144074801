import { Environment } from "../environments/Environment";

export class Captcha {
  static execute(action, formEl, cb) {
    grecaptcha.ready(function() {
      grecaptcha.execute(Environment.captchaSiteKey, { action }).then(function(token) {
        const captchaEl = formEl.querySelector('.captcha-code');

        if (captchaEl) {
          captchaEl.value = token;
        }

        cb();
      });
    });
  }
}
