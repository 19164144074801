export class ModalComponent {

  isVisible = false;

  _modalEl = null;
  _closeButtonEl = null;

  constructor(modalId) {
    this._modalEl = document.querySelector(`#${ modalId }`);

    if (this._modalEl) {
      this.init();
    }
  }

  init() {
    this._closeButtonEl = this._modalEl.querySelector('.close-modal-button');

    this.initListeners();
  }

  initListeners() {
    this._closeButtonEl.addEventListener('click', this.hide.bind(this));
  }

  show() {
    document.body.classList.add('overflow-hidden');
    this._modalEl.classList.add('active');
    this.isVisible = true;
  }

  hide() {
    document.body.classList.remove('overflow-hidden');
    this._modalEl.classList.remove('active');
    this.isVisible = false;
  }
}
