import scrollSpy from '@sidsbrmnn/scrollspy';

export class ScrollSpy {

  _navEl = null;
  _scrollSpyOptions = {
    offset: 250,
    activeClass: 'active',
    smoothScroll: true,
    hrefAttribute: 'data-href'
  };

  constructor() {
    this._navEl = document.querySelector('.nav');

    if (this._navEl && !location.pathname.includes('/job-offers/')) {
      this.init();
    }
  }

  init() {
    scrollSpy(this._navEl, this._scrollSpyOptions);
  }
}
