import { Loader } from "@googlemaps/js-api-loader";
import { Environment } from "../environments/Environment";
import { ContactFormComponent } from "./forms/contact-form.component";

export class ContactSectionComponent {

  _sectionEl = null;
  _mapContainerEl = null;
  _maps = null;
  _desktopMapPosition = {
    lat: 50.30,
    lng: 18.65
  };
  _mobileMapPosition = {
    lat: 50.294,
    lng: 18.662267168038312
  };
  _markerPosition = {
    lat: 50.2913069153847,
    lng: 18.662267168038312
  };
  _isDesktop = this.isDesktop;

  constructor() {
    this.init();
  }

  init() {
    this._sectionEl = document.querySelector('.contact-section-wrapper');

    if (this._sectionEl) {
      this.afterInit();
    }
  }

  afterInit() {
    this._mapContainerEl = this._sectionEl.querySelector('.map-container');

    this.initContactForm();
    this.loadMap();
  }

  initContactForm() {
    new ContactFormComponent();
  }

  loadMap() {
    this._googleMapsLoader = new Loader({
      apiKey: Environment.googleMapsKey,
    });

    this._googleMapsLoader.load().then(google => {
      this._maps = google.maps;

      this.renderMap();
      window.addEventListener('resize', this.onResize.bind(this));
    });
  }

  renderMap() {
    const mapOptions = {
      center: this.isDesktop ? this._desktopMapPosition : this._mobileMapPosition,
      zoom: 13,
      disableDefaultUI: true,
      styles: [{
        elementType: "geometry",
        stylers: [{
          color: "#f5f5f5"
        }]
      }, {
        elementType: "geometry.fill",
        stylers: [{
          color: "#f4f4f4"
        }]
      }, {
        elementType: "labels.icon",
        stylers: [{
          visibility: "off"
        }]
      }, {
        elementType: "labels.text.fill",
        stylers: [{
          color: "#616161"
        }]
      }, {
        elementType: "labels.text.stroke",
        stylers: [{
          color: "#f5f5f5"
        }]
      }, {
        featureType: "poi",
        elementType: "geometry",
        stylers: [{
          color: "#eeeeee"
        }]
      }, {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{
          color: "#757575"
        }]
      }, {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{
          color: "#e5e5e5"
        }]
      }, {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{
          color: "#9e9e9e"
        }]
      }, {
        featureType: "road",
        stylers: [{
          color: "#4C9099"
        }]
      }, {
        featureType: "road",
        elementType: "geometry",
        stylers: [{
          color: "#4C9099"
        }]
      }, {
        featureType: "road",
        elementType: "geometry.fill",
        stylers: [{
          color: "#A7B719"
        }]
      }, {
        featureType: "road.arterial",
        elementType: "geometry.fill",
        stylers: [{
          color: "#A7B719"
        }]
      }, {
        featureType: "road.arterial",
        elementType: "labels",
        stylers: [{
          visibility: "off"
        }]
      }, {
        featureType: "road.arterial",
        elementType: "labels.text.fill",
        stylers: [{
          color: "#757575"
        }]
      }, {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{
          color: "#dadada"
        }]
      }, {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [{
          color: "#4C9099"
        }]
      }, {
        featureType: "road.highway",
        elementType: "labels",
        stylers: [{
          visibility: "off"
        }]
      }, {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{
          color: "#616161"
        }]
      }, {
        featureType: "road.local",
        stylers: [{
          color: "#4C9099"
        }]
      }, {
        featureType: "road.local",
        elementType: "geometry.fill",
        stylers: [{
          color: "#8a8a8a"
        }]
      }, {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [{
          color: "#9e9e9e"
        }]
      }, {
        featureType: "road.local",
        elementType: "labels.text.stroke",
        stylers: [{
          color: "#f9f9f9"
        }]
      }, {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [{
          color: "#e5e5e5"
        }]
      }, {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [{
          color: "#eeeeee"
        }]
      }, {
        featureType: "water",
        elementType: "geometry",
        stylers: [{
          color: "#c9c9c9"
        }]
      }, {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{
          color: "#9e9e9e"
        }]
      }]
    };

    const map = new this._maps.Map(this._mapContainerEl, mapOptions);

    const icon = {
      url: '/assets/img/icons/marker.webp',
      scaledSize: new google.maps.Size(25, 40), // scaled size
    };

    new this._maps.Marker({
      position: this._markerPosition,
      map: map,
      icon: icon
    });
  }

  onResize() {
    const prevIsDesktopVal = this._isDesktop;
    this._isDesktop = this.isDesktop;

    if (prevIsDesktopVal !== this._isDesktop) {
      this.renderMap();
    }
  }

  get isDesktop() {
    return window.innerWidth > 767;
  }
}
