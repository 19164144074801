import Cookies from 'js-cookie';

export class CookiesComponent {

  _cookiesWrapperEl;
  _cookiesContentBasicEl;
  _cookiesContentAdvancedEl;
  _openAdvancedSettingButton;
  _backButton;
  _acceptButtons;
  _marketingToggleSwitchCheckboxEl;

  constructor() {
    this.init();
  }

  init() {
    this._cookiesWrapperEl = document.querySelector('.cookies-wrapper');

    if (this._cookiesWrapperEl) {
      this.afterComponentRender();
    }
  }

  afterComponentRender() {
    this._cookiesContentBasicEl = this._cookiesWrapperEl.querySelector('.cookies-wrapper__content--basic');
    this._cookiesContentAdvancedEl = this._cookiesWrapperEl.querySelector('.cookies-wrapper__content--advanced');
    this._openAdvancedSettingButton = this._cookiesWrapperEl.querySelector('.open-advanced-settings-btn');
    this._acceptButtons = this._cookiesWrapperEl.querySelectorAll('.accept-btn');
    this._backButton = this._cookiesWrapperEl.querySelector('.back-button');
    this._marketingToggleSwitchCheckboxEl = this._cookiesWrapperEl.querySelector('.marketing-checkbox');

    if (!Cookies.get('cookieSettings')) {
      this.toggleCookiesEl();
      this.openAdvancedSettings();
      this.openBasicSettings();
      this.acceptButtonsActions();
    } else if (JSON.parse(Cookies.get('cookieSettings')).analytics) {
      this.allowMarketing();
    }
  }

  openAdvancedSettings() {
    this._openAdvancedSettingButton.addEventListener('click', () => {
      this.setContentViewsClasses(this._cookiesContentBasicEl, this._cookiesContentAdvancedEl);
    });
  }

  openBasicSettings() {
    this._backButton.addEventListener('click', () => {
      this.setContentViewsClasses(this._cookiesContentAdvancedEl, this._cookiesContentBasicEl);
    });
  }

  acceptButtonsActions() {
    this._acceptButtons.forEach(x => {
      x.addEventListener('click', () => {
        const isMarketingChecked = this._marketingToggleSwitchCheckboxEl.checked;

        Cookies.set(
          'cookieSettings',
          JSON.stringify({
            analytics: isMarketingChecked
          }),
          {
            expires: new Date(2040, 0, 1)
          }
        );

        if (isMarketingChecked) {
          this.allowMarketing();
        }

        this.toggleCookiesEl();
      });
    })
  }

  allowMarketing() {
    dataLayer.push({ 'event': 'ccteam_allowed_analytics' });
  }

  toggleCookiesEl() {
    this._cookiesWrapperEl.classList.toggle('d-none');
  }

  setContentViewsClasses(el1, el2) {
    el1.classList.add('d-none');
    el2.classList.remove('d-none');
    this._cookiesWrapperEl.scrollTop = 0;
  }
}
