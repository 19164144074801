import { ModalComponent } from "./modals/modal.component";
import { JobApplyFormComponent } from "./forms/job-apply-form.component";

export class SendCvSectionComponent {

  _sectionEl = null;
  _jobApplyFormComponent = null;
  _jobApplyModal = null;

  constructor() {
    this.init();
  }

  init() {
    this._sectionEl = document.querySelector('.send-cv-section');

    if (this._sectionEl) {
      this.afterInit();
    }
  }

  afterInit() {
    const sendCVButton = this._sectionEl.querySelector('.send-cv-button');

    if (sendCVButton) {
      this._jobApplyModal = new ModalComponent('job-apply-modal');

      sendCVButton.addEventListener('click', () => {
        if (!this._jobApplyModal.isVisible) {
          this._jobApplyModal.show();
          this.initJobApplyFormComponent();
        }
      });
    }
  }

  initJobApplyFormComponent() {
    if (!this._jobApplyFormComponent) {
      this._jobApplyFormComponent = new JobApplyFormComponent();
    }
  }
}
